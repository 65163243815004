/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const Outfit_100Thin = require('./Outfit_100Thin.ttf');
export const Outfit_200ExtraLight = require('./Outfit_200ExtraLight.ttf');
export const Outfit_300Light = require('./Outfit_300Light.ttf');
export const Outfit_400Regular = require('./Outfit_400Regular.ttf');
export const Outfit_500Medium = require('./Outfit_500Medium.ttf');
export const Outfit_600SemiBold = require('./Outfit_600SemiBold.ttf');
export const Outfit_700Bold = require('./Outfit_700Bold.ttf');
export const Outfit_800ExtraBold = require('./Outfit_800ExtraBold.ttf');
export const Outfit_900Black = require('./Outfit_900Black.ttf');
